<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section
      class="section section-default section-press section-text"
      v-if="item"
    >
      <div class="container m-container-only-s">
        <ul class="header">
          <li>
            <strong>{{ item.title }}</strong>
          </li>
          <li>{{ item.issue_number }}</li>
        </ul>

        <div class="press-item-container">
          <picture>
            <source
              :srcset="getImage(article.image)"
              media="(max-width: 1599px)"
            />
            <img
              :src="getImage(article.image)"
              width="100%"
              height="auto"
              class="hero-image"
              alt=""
            />
          </picture>

          <h1 class="hero-headline">{{ article.title }}</h1>
          <div v-html="article.content"></div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "PressNumber",
  props: ["number", "id"],
  title() {
    return this.$t("pages.press");
  },
  data: () => {
    return {
      item: {},
      article: {}
    };
  },
  async mounted() {
    const { data } = await this.axios.get(
      "/storage/press/" + this.number + "/index.json"
    );
    this.item = data;

    this.article = this.item.items.find(item => item.id === +this.id);
  },
  methods: {
    getImage(image) {
      return `/storage/${image}`;
    }
  }
};
</script>

<style scoped></style>
